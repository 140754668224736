<template>
  <CreativeContentMunge dialog-name="Creative Content Munge" />
</template>
<script>
import CreativeContentMunge from '@/views/app/checker/CreativeContentMunge'

export default {
  name: 'CreativeContentMungeWrapper',
  components: {
    CreativeContentMunge
  }
}
</script>
