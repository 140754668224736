<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
    
          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>
      </v-card-title>
      <v-divider v-if="action === ``" />

      <!-- table -->
      <v-card-text v-if="action === ``" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="headers"
          @set-busy="setBusy"
          @on-item-reset="resetCreative"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === `account_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `creative_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.advertiser_category != ``">
                {{ row.prop.item.advertiser_category_name }} ({{
                  row.prop.item.advertiser_category
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `creative_id`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.creative_id > 0">
                {{ row.prop.item.creative_name }} ({{
                  row.prop.item.creative_id                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `created_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `updated_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.updated_by > 0">
                {{ row.prop.item.updated_by_name }} ({{
                  row.prop.item.updated_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `deleted_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

     

      <!-- filter -->
      <o-drawer
        v-if="!busy"
        :auto-close="true"
        :actions="[
          {
            key: 'on-item-reset',
            label: 'RESET',
            icon: 'mdi-refresh',
            color: 'warning',
          },
          {
            key: 'on-item-search',
            label: 'SEARCH',
            icon: 'mdi-text-box-search-outline',
            color: 'success',
          },
        ]"
        button-icon="mdi-filter-plus-outline"
        :title="`FILTERS`"
        :use-button="false"
        width="50vw"
        :visible="showFilter"
        @on-item-search="
          showFilter = false;
          reset();
        "
        @on-item-reset="
          showFilter = false;
          reset(true);
        "
        @on-close="showFilter = false"
      >
        <v-row class="pa-0 ma-0">
          <v-col cols="4" offset="4" style="display:none">
            <o-check-box v-model="strictFilter" :busy="busy" label="Strict" />
          </v-col>
          <v-col cols="12"  class="pa-0">
            <o-combo-box
              v-model="parameters.account_id"
              :busy="busy"
              label="Account"
              :items="accounts"
              :multiple="true"
            />
          </v-col> 
          <v-col cols="12" class="pa-0">            
            <o-input
              v-model="parameters.creative_id"
              :busy="busy"
              label="Manufacturer ID"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.manufacturer_name"
              :busy="busy"
              label="Name"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.manufacturer_domain"
              :busy="busy"
              label="Domain"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-combo-box
              v-model="parameters.active"
              :busy="busy"
              label="Active"
              :items="booleans"
              :multiple="true"
              :hasId="false"
            />
          </v-col>
        </v-row>
      </o-drawer>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: `CreativeContentMunge`,
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: `Creative Content Munge`,
  },
  data() {
    return {
      title: `Creative Content Munge`,
      busy: false,
      strictFilter: false,
      action: ``,
      valid: false,
      baseModel: {
        id: 0,
        account_id: 0,
        manufacturer_id: 0,
        manufacturer_name: "",
        advertiser_category: "",
        manufacturer_domain: "",
        active: true,
        notes: "",
        created_at: "",
        created_by: 0,
        updated_at: "",
        updated_by: 0,
        deleted_at: "",
        deleted_by: 0,
      },
      model: {},
      originalModel: {},
      parameters: {
        account_id: [],
        advertiser_category: [],
        manufacturer_id: "",
        manufacturer_name: "",
        manufacturer_domain: "",
        active: []        
      },
      headers: [
        {
          text: `#`,
          align: `end`,
          value: `row_no`,
          type: `int`,
          width: 100,
        },
        {
          text: `Account Name`,
          align: `start`,
          sortable: false,
          value: `account_name`,
          type: `slot`,
        },
        {
          text: `Creative`,
          align: `start`,
          sortable: false,
          value: `creative_id`,
          type: `slot`,
        }, 
        {
          text: `Valid`,
          align: `center`,
          value: `correct`,
          type: `boolean`,
          width: 100,
        }, 
        {
          value: `action`,
          type: `action`,
          width: 100,
          actions: [
            {
              key: `on-item-reset`,
              label: `Reset XML`,
              icon: `mdi-replay`,
              color: `success`,
            },
          ],
        }, 
      ],
      accounts: [],
      advertiser_categories: [],
    };
  },
  computed: {
    ...mapGetters([`loggedInUser`]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      `ROW_NUMBER() OVER(ORDER BY a.account_id, a.creative_id) row_no`,
      `a.*`,
      'b.account_name'
    ];
    this.listParameters.sources = {
      main: `vw_creative_munge_checker a`,
      children: [
        {
          name: `accounts b`,
          on: `a.account_id = b.account_id`,
        },  
      ],
    };
    this.listParameters.orders = [
      {
        sort: `a.account_id`,
        dir: `ASC`,
      }, 
      {
        sort: `a.creative_id`,
        dir: `ASC`,
      },
    ];
    for (const [k, v] of Object.entries(this.parameters)) { 
      if (this.$route.query[k]) {
        this.parameters[k] = this.$route.query[k];
      }
    }     
    
  },
  async mounted() {
     this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    ); 
  },
  methods: {
    async reset(clear) { 
      this.model = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
    async resetCreative(item) {
      const model = item;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are resetting <strong>${model.creative_name} (${model.creative_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          let errors = []; 
          const url = `/rest/intranet/query`;
          if (ans) {
            const parameters = _.cloneDeep(this.updateParameters);
            parameters.table = `creatives`;
            parameters.fields.push({
              field: `creative_content_munge`,
              value: null,
            });
            parameters.fields.push({
              field: `content_update_date`,
              value: new Date().toISOString(),
            }); 

            parameters.wheres.push({ field: `account_id`, value: model.account_id });
            parameters.wheres.push({ field: `creative_id`, value: model.creative_id });
            await this.postData(url, parameters)
              .then(async (response) => {
                this.$refs.container.snackBar(
                  `Successfully updated ${model.creative_name} (${model.creative_id}).`,
                  `green`,
                  2000
                );
                this.reset();
                await window.refreshCritical();
              })
              .catch((err) => {
                errors.push(err.data.message);
              });
          }

          if (errors.length > 0) {
            this.$refs.container.snackBar(
              `Error updating item: ${errors.join(",")}`,
              `red`,
              2000
            );
          } else {
            this.action = ``;
          }
          
        });
    }, 
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
